<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Custom Folder Views</h1>
      <div class="btns-container">
        <VasionButton
          id="btnNewGroup"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="editViewClick(0)"
        >
          New Folder View
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="ColumnValues"
          :hideColumns="hiddenColumns"
          :tableRows="tableData.Rows.Values"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
        <div class="index-fields" />
      </div>
    </div>
    <md-dialog id="confirm-delete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="toggleDeleteDialog()" @yesButtonClick="deleteFolderViewAndToggle()" />
    </md-dialog>

    <VasionSnackbar
      id="custom-folder-view-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'CustomFolderViewConfig',
  components: {
    Loading,
  },
  data: function () {
    return {
      activeFolderView: {},
      ColumnValues: [
        'fields',
        'folderId',
        'Name',
        'id',
        'showCheckedOutUser',
        'showCreatedDate',
        'showCreatedDateInReport',
        'showDocumentCount',
        'showDocumentName',
        'showDocumentNameInReport',
        'showFolderName',
        'showFolderNameInReport',
        'showFormInReport',
        'showFormName',
        'showLegalHold',
        'showLegalHoldInReport',
        'showModifiedDate',
        'showModifiedDateInReport',
        'showUploaded',
        'showUserGroup',
        'showUserGroupInReport',
        'showWorkflowName',
        'showWorkflowStatus',
        'showFileSize',
        'showFileSizeInReport',
        '_VasionEllipsisButton_',
      ],
      deleteFolderViewID: 0,
      deletePromptMessage: '',
      hiddenColumns: [
        'fields',
        'folderId',
        'id',
        'showCheckedOutUser',
        'showCreatedDate',
        'showCreatedDateInReport',
        'showDocumentCount',
        'showDocumentName',
        'showDocumentNameInReport',
        'showFolderName',
        'showFolderNameInReport',
        'showFormInReport',
        'showFormName',
        'showLegalHold',
        'showLegalHoldInReport',
        'showModifiedDate',
        'showModifiedDateInReport',
        'showUploaded',
        'showUserGroup',
        'showUserGroupInReport',
        'showWorkflowName',
        'showWorkflowStatus',
        'showFileSize',
        'showFileSizeInReport',
      ],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {
        Rows: {
          Values: [],
        },
      },
      viewErrorText: '',
      viewName: '',
    }
  },
  computed: {
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    fieldNames() { return this.$store.state.storage.uniqueFieldNames },
    viewList() { return this.$store.state.search.fullFolderSearchConfigurations },
  },
  created: function () {
    this.refreshTable()
    this.$store.dispatch('security/setEllipsisButtonConfig', 'custom-folder-view')
  },
  methods: {
    copyViewClick(viewID) {
      this.activeFolderView = this.viewList.find((view) => {
        return view.id === viewID
      })

      this.activeFolderView.id = 0
      this.activeFolderView.folderID = 0
      this.activeFolderView.folderName = ''
      this.customFolderViewDetails = true
      this.$store.dispatch('search/updateActiveFolderView', this.activeFolderView)
      this.$router.push({ name: 'CustomFolderViewDetails', params: { viewID } })
    },
    async deleteFolderViewAndToggle() {
      const result = await this.$store.dispatch('storage/deleteCustomFolderView', this.deleteFolderViewID)

      this.toggleDeleteDialog()

      if (!result || result.Value !== 'True') {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Unable to Delete.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }

      this.refreshTable()
    },
    deleteViewClick(viewID, folderName) {
      this.deletePromptMessage = `Are you sure you want to delete "${folderName}"?`
      this.deleteFolderViewID = viewID
      this.showDeleteDialog = true
    },
    editViewClick(viewID) {
      this.activeFolderView = this.viewList.find((view) => {
        return view.id === viewID
      })

      if (!this.activeFolderView) {
        this.activeFolderView = {
          id: 0,
          folderID: 0,
          showDocumentName: false,
          showLegalHold: false,
          showCreatedDate: false,
          showUploaded: false,
          showWorkflowStatus: false,
          showModifiedDate: false,
          showUserGroup: false,
          showFolderName: false,
          showFormName: false,
          showWorkflowName: false,
          showCheckedOutUser: false,
          showDocumentCount: false,
          showFileSize: false,
          showDocumentNameInReport: false,
          showLegalHoldInReport: false,
          showCreatedDateInReport: false,
          showModifiedDateInReport: false,
          showUserGroupInReport: false,
          showFormInReport: false,
          showFolderNameInReport: false,
          showFileSizeInReport: false,
          folderName: '',
          fields: [],
        }
      }

      this.customFolderViewDetails = true

      this.$store.dispatch('search/updateActiveFolderView', this.activeFolderView)
      this.$router.push({ name: 'CustomFolderViewDetails', params: { viewID } })
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [, , name, id] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-custom-folder-view-button':
            this.editViewClick(id)
            break;
          case 'copy-custom-folder-view-button':
            this.copyViewClick(id)
            break;
          case 'delete-custom-folder-view-button':
            this.deleteViewClick(id, name)
            break;
          default:
            break;
        }
      }
    },
    async refreshTable() {
      this.isLoading = true

      await Promise.all([
        this.$store.dispatch('search/getFullFolderSearchConfigurations'),
        this.$store.dispatch('storage/loadUniqueFieldNames'),
      ])

      this.tableData = {
        Rows: {
          Values: [],
        },
      }
      this.tableData.Rows.Values = this.viewList.map((viewElement) => {
        const dataRow = [
          viewElement.fields,
          viewElement.folderID,
          viewElement.folderName,
          viewElement.id,
          viewElement.showCheckedOutUser,
          viewElement.showCreatedDate,
          viewElement.showCreatedDateInReport,
          viewElement.showDocumentCount,
          viewElement.showDocumentName,
          viewElement.showDocumentNameInReport,
          viewElement.showFolderName,
          viewElement.showFolderNameInReport,
          viewElement.showFormInReport,
          viewElement.showFormName,
          viewElement.showLegalHold,
          viewElement.showLegalHoldInReport,
          viewElement.showModifiedDate,
          viewElement.showModifiedDateInReport,
          viewElement.showUploaded,
          viewElement.showUserGroup,
          viewElement.showUserGroupInReport,
          viewElement.showWorkflowName,
          viewElement.showWorkflowStatus,
          viewElement.showFileSize,
          viewElement.showFileSizeInReport,
          'VasionEllipsisIcon',
        ]

        const retElement = {
          Values: dataRow,
          ValueType: 'String',
        }

        return retElement
      })

      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.editViewClick(data.Values[3])
    },
    toggleDeleteDialog() { this.showDeleteDialog = !this.showDeleteDialog },
  },
}
</script>

<style lang="scss" scoped>
#btnNewGroup {
  z-index: 0;
}

.grid-div {
  margin-top: 3px;
  height: calc(100vh - 224px);
  overflow: auto;
}
</style>
